import { QueueTokenResponse, SkdfLoginForm } from '@/types/User'
import { fetchWrapper } from './fetchApi'
import { IQueueResponse } from '@/types/Export';

export const exportRoadsApi = {
  async fetchToken(form: { username: string; password: string }) {
    try {
      const response = await fetchWrapper.post('/external/skdf/auth/', form)
      return response
    } catch (e) {
      throw new Error(e)
    }
  },
  async skdfAuthQueue(loginForm: SkdfLoginForm) {
    try {
      const response = (await fetchWrapper.post(
        '/auth/',
        {
          username: loginForm.username,
          password: loginForm.password
        },
        'http://10.10.10.79:40105'
      )) as QueueTokenResponse

      return response
    } catch (e) {
      throw new Error(e.message)
    }
  },
  async sendGeometryToSkdf(params: { token: string; roadId: number; idSkdf: number }) {
    try {
      const response = await fetchWrapper.post(
        `/external/skdf/multi_write_geom`,
        {
          isSkdf: true,
          token: params.token,
          list_road_id: [params.roadId],
          list_id_skdf: [params.idSkdf]
        },
        undefined
      ) as IQueueResponse[]

      return response
    } catch (e) {
      throw new Error(e.message)
    }
  },
  async sendRoadCardToSkdf(params: { token: string; roadId: number; idSkdf: number }) {
    try {
      const response = await fetchWrapper.post(
        `/external/skdf/multi_write_card`,
        {
          isSkdf: true,
          token: params.token,
          list_road_id: [params.roadId],
          list_id_skdf: [params.idSkdf]
        },
        undefined
      ) as IQueueResponse[]

      return response
    } catch (e) {
      throw new Error(e.message)
    }
  },
  async updateCard() {
    try {
      const response = await fetchWrapper.post(
        'skdf/roads/save-card',
        {
          isSkdf: true
        },
        import.meta.env.VITE_SKDF_API
      )

      return response
    } catch (e) {
      throw new Error(e)
    }
  }
}
