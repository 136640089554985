import { RouteLocation } from 'vue-router'

import { TBreadcrumb } from '@/types/ui/Breadcrumb'

export function Breadcrumb(
  this: TBreadcrumb,
  name: string,
  to: RouteLocation | null,
  id = '',
  type = '',
  samePage = false
) {
  this.name = name
  this.to = to
  this.samePage = samePage
  this.id = id
  this.type = type
}
