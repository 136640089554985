import { onUnmounted, Ref, ref, watch } from "vue"
import { storeToRefs } from "pinia"

import { useExportRoadStore } from '@/stores/export'
import { ITask } from "@/types/Queue"
import { TaskStatus } from "@/utils/consts/consts"
import { IRoadWithStatus } from "@/types/Road"
import { queueApi } from "@/api/queue"
import { TTimer } from "@/utils/Timeout"
import { IFetchTaskParams } from "@/types/Export"

export function useRoadStatusExport() {
  const exportRoadStore = useExportRoadStore()

  const { roads, tasks } = storeToRefs(exportRoadStore)

  const intervals = ref([])

  const roadWithStatus: Ref<IRoadWithStatus[]> = ref([...roads.value])

  onUnmounted(() => {
    intervals.value.forEach((el) => clearInterval(el))
    intervals.value.length = 0
  })

  startWatch(intervals, roadWithStatus, tasks)

  watch(roads, (val) => {
    roadWithStatus.value = [...val]
    startWatch(intervals, roadWithStatus, tasks)
  })

  return {
    roadWithStatus,
    checkExportStatus: checkExportStatus.bind(null, intervals)
  }
}

function startWatch(intervals: Ref<TTimer[]>, roads: Ref<IRoadWithStatus[]>, tasks: Ref<ITask[]>) {
  intervals.value.forEach((el) => clearInterval(el))
  intervals.value.length = 0

  applyStatus(roads.value, tasks.value)

  const inProcess = tasks.value.filter(
    (task) => (task.status === TaskStatus.IN_PROGRESS || task.status === TaskStatus.PENDING) && task.info.skdf_number
  )

  if (inProcess.length) {
    for (let i = 0; i < inProcess.length; i += 1) {
      checkExportStatus(intervals, {
        qid: inProcess[i].id,
        roads: roads.value
      })
    }
  }
}

function applyStatus(roads: IRoadWithStatus[], tasks: ITask[]) {
  const roadTasks = tasks.filter((task) => task.task_object === 'Road')

  roads.forEach((road) => {
    const task = roadTasks.find((task) => task.task_object_id === road.id && task.info.skdf_number)
    if (task) {
      road.task_status = defineStatus(task)
      road.status_errors = task.info.fields_errors
    }
  })
}

function defineStatus(task: ITask) {
  return task.status === TaskStatus.COMPLETED
    ? TaskStatus.COMPLETED
    : task.status === TaskStatus.FAILED
    ? TaskStatus.FAILED
    : TaskStatus.IN_PROGRESS
}

function checkExportStatus(intervals: Ref<TTimer[]>, params: IFetchTaskParams) {
  const { qid, roads } = params

  const intervalId = setInterval(() => {
    queueApi.fetchTask(qid).then((task) => {
      if (task.status !== TaskStatus.PENDING && task.status !== TaskStatus.IN_PROGRESS) {
        clearInterval(intervalId)
        intervals.value = intervals.value.filter((timeId) => timeId !== intervalId)
      }

      const road = roads.find((el) => el.id === task.task_object_id)

      if (road) {
        road.task_status = defineStatus(task)
        road.status_errors = task.info.fields_errors
      }
    })
  }, 3000)

  intervals.value.push(intervalId)
}
