<script setup lang="ts">
import { computed, inject, onBeforeUnmount, onMounted, Ref, ref, watch, nextTick } from 'vue'

import { fetchWrapper } from '@/api/fetchApi'
import useDefectPopup from '@/composition/defects/useDefectPopup'
import useDraggable from '@/composition/useDraggable'
import { useRoadDefectsPopupStore } from '@/stores'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter'

import BackgroundContextMenu from '@/components/layouts/background/BackgroundContextMenu.vue'

import { DefectPopupProps } from '@/types/ui/Chart'

const props = defineProps<DefectPopupProps>()
const emits = defineEmits(['close', 'open'])

const roadDefectFilterStore = useDefectFilterStore()
const defectPopupStore = useRoadDefectsPopupStore()
const { currentStyle, visibleDefectInPopup, calculateXCoordinate } = useDefectPopup()

const pageWrapper: Ref<HTMLElement | undefined> | undefined = inject('pageWrapper')

const defectPopup = ref()

const defectTypes = computed(() => roadDefectFilterStore.defectTypes)
const style = computed(() => roadDefectFilterStore.style)
const data = computed(() => props.data)
const position = computed(() => props.position)
const defectData = computed(() => visibleDefectInPopup(defectTypes.value, data.value))
const images: Ref<string[]> = ref([])

const coors = computed({
  get: () => ({
    x: calculateXCoordinate(position.value.x),
    y: position.value.y
  }),
  set: (val) => {
    const position = {
      x: val.x,
      y: val.y
    }

    defectPopupStore.updateCoordinates(position)
    defectPopupStore.updateFromDraggbale(true)
  }
})

const drag = useDraggable(defectPopup, coors, pageWrapper)

onMounted(() => {
  if (!data.value) throw new Error('Недостаточно данных')
})

onBeforeUnmount(() => {
  drag.removeDrag()
})

watch(
  defectData,
  async (val) => {
    emits('close')

    if (val.length) {
      const binaryData = await Promise.all(
        [data.value?.at(0)?.photo, ...defectData.value.map((el) => el?.photo_mask)]
        .filter((el) => el)
        .map((el) => fetchWrapper.download(el, { method: 'get'}, undefined, true))
      ) as [Blob, string][]

      images.value = binaryData.map((el) => URL.createObjectURL(el[0]))

      nextTick(() => {
        emits('open')
      })
    }
  },
  {
    deep: true
  }
)

function closeDefectInfo() {
  emits('close')
}
</script>

<template>
  <div
    ref="defectPopup"
    draggable
    class="defects-popup"
    :style="{ top: coors.y + 'px', left: coors.x + 'px' }"
  >
    <BackgroundContextMenu :second-gradient="false"></BackgroundContextMenu>
    <div class="defects-popup__wrapper">
      <span class="defects-popup__close" @click="closeDefectInfo"></span>
      <div class="defects-popup__imgContainer">
        <img
          class="imgContainer__item"
          :src="images[0]"
          alt="Фото дефекта"
        />
        <img
          v-for="(item, index) in defectData"
          :key="index + '-defect-mask'"
          class="imgContainer__item colorMask"
          :style="{
            opacity: (currentStyle(style, item)?.opacity ?? 100) / 100,
            filter: currentStyle(style, item)?.filter?.join('')
          }"
          :src="images[index + 1]"
          alt="Маска фото дефекта"
        />
      </div>
      <div class="defects-popup__content custom-scroll">
        <div
          v-for="(item, index) in defectData"
          :key="index + '-defect-popup-info'"
          class="defects-popup__info"
        >
          <div class="defects-popup__defectInfo">
            <h4>
              {{ item?.label || '' }} - <span>{{ item?.count_class }}</span> шт.
            </h4>
            <p class="defects-popup__infoItem">
              Средняя площадь:
              {{ Math.floor(item?.sum_square / item?.count_class) }} см²
            </p>
            <p class="defects-popup__infoItem">
              Средняя длина: {{ Math.floor(item?.sum_length / item?.count_class) }} см
            </p>
            <p class="defects-popup__infoItem">
              Средняя ширина: {{ Math.floor(item?.sum_width / item?.count_class) }} см
            </p>
            <p class="defects-popup__infoItem">Максимальная площадь: {{ item?.max_square }} см²</p>
            <p class="defects-popup__infoItem">Максимальная длина: {{ item?.max_length }} см</p>
            <p class="defects-popup__infoItem">Максимальная ширина: {{ item?.max_width }} см</p>
            <p class="defects-popup__infoItem">Минимальная площадь: {{ item?.min_square }} см²</p>
            <p class="defects-popup__infoItem">Минимальная длина: {{ item?.min_length }} см</p>
            <p class="defects-popup__infoItem">Минимальная ширина: {{ item?.min_width }} см</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.defects-popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  box-shadow: 5px 5px 15px 0 rgba(0 0 0 / 35%);
  cursor: grab;
  user-select: none;

  &__wrapper {
    display: grid;
    position: relative;
    z-index: 10;
    grid-auto-flow: column;
    grid-gap: 0 30px;
    align-items: start;
    padding: 35px 30px;
    transition: all 0.2s linear;
  }

  &__content {
    display: grid;
    grid-gap: 25px 0;
    height: 100%;
    max-height: 454px;
    overflow: auto;
  }

  &__defectInfo {
    padding: 10px 40px 10px 10px;
    background: rgba(255 255 255 / 7%);

    @include color('fontColor');

    :first-child {
      margin-bottom: 10px;
    }
  }

  &__close {
    display: inline-block;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    mask-image: url('@/assets/images/icons/close.svg');

    @include background-color('fontColor');

    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
  }

  &__imgContainer {
    position: relative;
    border: 1px solid rgba(255 255 255 / 7%);
  }

  .imgContainer {
    &__item {
      display: block;
      width: 230px;
      height: 450px;
      pointer-events: none;
      user-select: none;

      &.test {
        border: 1px solid #e48569;
      }

      &.colorMask {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    min-width: 230px;
  }

  &__infoItem {
    margin-top: 5px;
    font: {
      family: $f-family-base;
      weight: 400;
      size: 0.9rem;
    }
  }

  &__info-title {
    margin-top: 10px;
    font-family: $f-family-base;
    font-size: 16px;
  }

  &__info-item {
    &:nth-child(2) {
      margin: 5px 0 0;
    }

    font-size: 13px;
  }

  &__to-map {
    margin-top: auto;
    margin-left: auto;
    border: none;
    background-color: transparent;
    color: $c-white;
    font-family: $f-family-base;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
}
</style>
