export enum DiagramTypes {
  DIAGRAM = 'diagram',
  SCALE = 'scale'
}

export enum Directions {
  UP = 'up',
  DOWN = 'down'
}

export enum Positions {
  CENTER = 'center'
}

export enum MapLayers {
  SCHEME = 'composite-custom',
  SATELLITE = 'satellite-custom',
  HYBRID = 'hybrid-custom',
  YANDEX = 'yandex-custom'
}

export enum APP_THEMES {
  DARK = 'theme-dark',
  LIGHT = 'theme-light'
}

export enum RoadObjectsTypeId {
  ROAD_SIGN = 1,
  KILOMETER_POL = 2,
  ROADWAY = 3,
  LAND_LOTS = 4,
  ROAD_MARKS = 5,
  SIDE_WALKS = 6,
  ROAD_REALTY = 7,
  ROAD_LIGHT = 8,
  ROAD_EDGES = 9,
  BRIDGES = 10,
  CURVES = 11,
  CLIMATIC_ZONE = 12,
  BORDERS = 13,
  SEMAPHORES = 14,
  BUMPS = 15,
  WEIGHT_CONTROL = 16,
  CROSSWALKS = 17,
  ROAD_EXIT = 18,
  GUIDE_STAKE_SEGMENTS = 19,
  EXPLUATATION_CATEGORY = 20,
  ROAD_FENCE_SEGMENTS = 21,
  RAIL_WAY_CROSSING = 22,
  LIGHTING_POLES = 23,
  ROAD_FORTIFICATIONS = 24,
  ROAD_LANDSCAPING = 25,
  ADJACENT_LANDS = 26,
  TRUM_LINE = 27,
  STROM_DRAIN_WELL = 28,
  WATER_DISPOSAL = 29,
  BUS_STOP = 30,
  SQUARE = 31,
  SEPARATE_LINE = 32,
  ROAD_AXIS = 34
}

export const layers = [
  'land-lots',
  'roadway',
  'road',
  'side-walks',
  'realty',
  'edges',
  'light',
  'light-fill',
  'light-line',
  'light-circle',
  'light-symbol'
]

export enum DefectTypeId {
  default = 1
}

export enum EgrnLayers {
  BUILDS = 'egrn-custom-builds',
  LANDS = 'egrn-custom-lands',
  CONSTRUCTIONS = 'egrn-custom-constructions',
  CONSTRUCTIONS_LINES = 'egrn-custom-constructions_lines',
  CONSTRUCTIONS_CURVES = 'egrn-custom-constructions_curves'
}

export enum GeometryMapLayers {
  ROAD_ELEMENTS = 'road-custom-elements',
  EGRN_CUSTOM = 'egrn-custom',
  ORTO = 'orto-',
  KERN = 'kern-custom',
  ROADSIDE_STRIP = 'roadside-strip',

  ROAD_LAND_LOTS = 'road-custom-land-lots',
  ROAD_WAY = 'road-custom-way',
  ROAD_SIDE_WALKS = 'road-custom-side-walks',
  ROAD_REALTY = 'road-custom-realty',
  ROAD_LIGHT = 'road-custom-light',
  ROAD_EDGES = 'road-custom-edges',
  ROAD_BRIDGES = 'road-custom-bridges',
  ROAD_CURVES = 'road-custom-curves',
  ROAD_CLIMATIC = 'road-custom-climatic',
  ROAD_BORDERS = 'road-custom-borders',
  ROAD_SEMAPHORES = 'road-custom-semaphores',
  ROAD_WEIGHT_CONTROL = 'road-custom-weight-control',
  ROAD_EXIT = 'road-custom-exit',
  ROAD_AXIS = 'road-custom-axis',
  ROAD_SIGN = 'road-custom-sign',
  ROAD_BUMPS = 'road-custom-bumps',
  ROAD_CROSSWALKS = 'road-custom-crosswalks',
  ROAD_GUIDE_STAKE_SEGMENTS = 'road-custom-guide-stake-segments',
  ROAD_CATEGORY_SEGMENTS = 'road-custom-category-segments',
  ROAD_FENCE_SEGMENTS = 'road-custom-fence-segments',
  RAILWAY_CROSSING = 'road-custom-railway-crossing',
  EXPLUATATION_CATEGORY = 'road-custom-expluatation-category',
  LIGHTING_POLES = 'road-custom-lighting-poles',
  ROAD_FORTIFICATIONS = 'road-custom-fortifications',
  ROAD_LANDSCAPING = 'road-custom-landscaping',
  ADJACENT_LANDS = 'road-custom-adjacent-lands',
  TRUM_LINE = 'road-custom-trum-line',
  STROM_DRAIN_WELL = 'road-custom-storm-drain-well',
  WATER_DISPOSAL = 'road-custom-water-disposal',
  BUS_STOP = 'road-custom-bus-stop',
  SQUARE = 'road-custom-square',
  SEPARATE_LINE = 'road-custom-separate-line',

  ROAD_MARKS = 'road-custom-marks',
  ROAD_MARKS_SOLID = 'road-custom-marks_1.1',
  ROAD_MARKS_SOLID_2 = 'road-custom-marks_1.2',
  ROAD_MARKS_DOUBLE_SOLID = 'road-custom-marks_1.3',
  ROAD_MARKS_SOLID_YELLOW_BUS = 'road-custom-marks_1.4',
  ROAD_MARKS_DASHED_LONG = 'road-custom-marks_1.5',
  ROAD_MARKS_DASHED_SHORT = 'road-custom-marks_1.6',
  ROAD_MARKS_DASHED_SHORT_2 = 'road-custom-marks_1.7',
  ROAD_MARKS_DASHED_SHORT_3 = 'road-custom-marks_1.8',
  ROAD_MARKS_REVERSE = 'road-custom-marks_1.9',
  ROAD_MARKS_YELLOW_DASHED = 'road-custom-marks_1.10',
  ROAD_MARKS_SOLID_WITH_DASHED = 'road-custom-marks_1.11',
  ROAD_MARKS_STOP_LINE = 'road-custom-marks_1.12',
  ROAD_MARKS_GIVE_WAY = 'road-custom-marks_1.13',
  ROAD_MARKS_CROSSWALK = 'road-custom-marks_1.14.1',
  ROAD_MARKS_CROSSWALK_2 = 'road-custom-marks_1.14.2',
  ROAD_MARKS_CROSSWALK_DIAGONAL = 'road-custom-marks_1.14.3',
  ROAD_MARKS_INTERSECTION_BICYKLE = 'road-custom-marks_1.15',
  ROAD_MARKS_DELIMITER_OPPOSITE = 'road-custom-marks_1.16.1',
  ROAD_MARKS_DELIMITER_PASSING = 'road-custom-marks_1.16.2',
  ROAD_MARKS_DELIMITER_PASSING_2 = 'road-custom-marks_1.16.3',
  ROAD_MARKS_BUS_STOP_LINE = 'road-custom-marks_1.17.1',
  ROAD_MARKS_RAILWAY = 'road-custom-marks_1.17.2',
  ROAD_MARKS_ROUTE_LINE = 'road-custom-marks_1.18',
  ROAD_MARKS_NARROWING = 'road-custom-marks_1.19',
  ROAD_MARKS_GIVE_WAY_2 = 'road-custom-marks_1.20',
  ROAD_MARKS_STOP = 'road-custom-marks_1.21',
  ROAD_MARKS_ROAD_NUMBER = 'road-custom-marks_1.22',
  ROAD_MARKS_ROUTE_LINE_2 = 'road-custom-marks_1.23.1',
  ROAD_MARKS_PEDESTRIAN_LINE = 'road-custom-marks_1.23.2',
  ROAD_MARKS_BICYKLE_LINE = 'road-custom-marks_1.23.3'
}

export enum MapRoadsLayers {
  ROADS = 'roads'
}

export enum GeometryMapLayerSuffixes {
  CLUSTERS = '-clusters',
  LINES = '-line',
  CIRCLES = '-circle',
  FILLS = '-fill',
  UNCLUSTERED_POINTS = '-unclustered-point',
  Z_INDEX = '-z-index'
}

export enum RoadInfoNames {
  ID = 'Идентификационный номер',
  REGISTRATION = 'Учетный номер',
  CORE_NETWORK = 'Входит в опорную сеть',
  SKDF_NUMBER = 'Номер СКДФ',
  TERRITORY = 'Муниципальное образование',
  REGION = 'Регион',
  CITY = 'Город',
  LOCALITY = 'Населенный пункт',
  AREA = 'Район',
  OWNER = 'Собственник, владелец дороги (балансодержатель)',
  BOOK_VALUE = 'Балансовая стоимость, тыс. руб.',
  RESIDUAL_VALUE = 'Остаточная стоимость, тыс. руб.'
}

export enum RoadDetailNames {
  MEANING = 'Значение автомобильной дороги',
  COMISSIONING_DATE = 'Дата ввода в эксплуатацию',
  TECHNICAL_CATEGORY = 'Техническая категория',
  CLASS = 'Класс',
  TYPES = 'Виды покрытия',
  CATEGORY_SP = 'Категория согласно СП 42.13330',
  INTERNATIONAL_ROAD = 'Международный маршрут',
  TYPE_OF_PERMITTED_USE = 'Вид разрешенного использования',
  ROAD_CLIMATE_ZONE = 'Дорожно-климатическая зона',
  EGRAD_NUMBER = 'Номер ЕГРАД',
  START_ADRESS = 'Адрес начала дороги, км+м',
  END_ADRESS = 'Адрес конца дороги, км+м',
  SERVICE_LIFE_LIMIT = ' Предельный срок эксплуатации, месяц',
  WEAR = 'Износ, %'
}

export enum FilterMaxValueNames {
  max_square = 'max_square',
  max_length = 'max_length',
  max_width = 'max_width'
}

export enum FilterMinValueNames {
  min_square = 'min_square',
  min_width = 'min_width',
  min_length = 'min_length'
}

export enum RoadDefectLayers {
  CRACK = 'crack-defect',
  POTHOLE = 'pothole-defect'
}

export enum AppMapIds {
  GEOMETRY = 'geometry-map',
  MAIN_ROAD_PAGE = 'main-road-page',
  ROAD_WAY_PAGE = 'road-way-page',
  ROAD_DIAGNOSTIC_DEFECTS_PAGE = 'road-diagnostic-defects-page',
  MAP_PAGE = 'map-page'
}

export enum DEFECTS_IDS {
  CRACK = 1,
  POTHOLE = 2
}

export enum ECHARTS_ACTION {
  DOWNPLAY = 'downplay',
  HIGHLIGHT = 'highlight'
}

export enum ProfileNavigation {
  PROFILE = 'profile',
  REFERENCES = 'references',
  IMPORT = 'import',
  EXPORT = 'export'
}
