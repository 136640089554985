<script setup lang="ts">
import { Ref, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useDebounceFn } from '@vueuse/core'

import { exportRoadsApi } from '@/api/export'
import { useExportRoadStore } from '@/stores/export'
import { DEBOUNCE_INPUT_DELAY, TaskStatus } from '@/utils/consts/consts'
import { useToastStore } from '@/stores'
import { useRoadStatusExport } from '@/composition/useRoadStatusExport'

import AppPopup from '@/components/ui/AppPopup.vue'
import AppPagination from '@/components/ui/AppPagination.vue'
import Button from '@/components/ui/Button.vue'
import InputField from '@/components/ui/InputField.vue'
import AppCheckbox from '@/components/ui/AppCheckbox.vue'
import SkdfPopup from './SkdfPopup.vue'
import AppPreloaderInteractive from '@/components/ui/AppPreloaderInteractive.vue'

import type { IRoadWithStatus, RoadResponse } from '@/types/Road'
import { TTimer } from '@/utils/Timeout'
import { IQueueResponse } from '@/types/Export'
import { queueApi } from '@/api/queue'

const exportRoadStore = useExportRoadStore()
const toastStore = useToastStore()

const { search, selectedRoads, pagination, skdfToken, roads } = storeToRefs(exportRoadStore)

const { roadWithStatus, checkExportStatus } = useRoadStatusExport()

const isOpen = ref(false)
const selectAllCb = ref(false)
const loading = ref(false)
const withoutSkdf: Ref<RoadResponse[]> = ref([])
const intervals: Ref<TTimer[]> = ref([])
let currentPage = 1

async function changePage(page: number) {
  intervals.value.forEach((id) => clearInterval(id))
  intervals.value.length = 0

  await exportRoadStore.fetchRoads(page)

  const selected = roadWithStatus.value.filter((road) =>
    selectedRoads.value.find((selected) => selected.id === road.id)
  )

  currentPage = page

  selectAllCb.value = !!selected.length
}

function startSearch(event: InputEvent) {
  const t = event.target as HTMLInputElement
  search.value = t.value

  exportRoadStore.fetchRoads()
}

const searchRoad = useDebounceFn(startSearch, DEBOUNCE_INPUT_DELAY)

function select(e: Event, road: RoadResponse) {
  if ((e.target as HTMLElement).classList.contains('export__link')) return

  const index = selectedRoads.value.findIndex((selected) => selected.id === road.id)

  if (index > -1) {
    selectedRoads.value.splice(index, 1)
  } else {
    selectedRoads.value.push(road)
  }
}

function selectAll(e: Event) {
  const t = e.target as HTMLInputElement
  selectAllCb.value = t.checked

  if (selectAllCb.value) {
    selectedRoads.value = [...new Set([...selectedRoads.value, ...roadWithStatus.value])]
  } else {
    const selectedRoadsOnPage = selectedRoads.value.filter((selected) =>
      roadWithStatus.value.find((road) => selected.id === road.id)
    )

    selectedRoadsOnPage.forEach((selected) => {
      const index = selectedRoads.value.indexOf(selected)
      if (index > -1) selectedRoads.value.splice(index, 1)
    })
  }
}

function clear() {
  selectedRoads.value.length = 0
  selectAllCb.value = false
  roadWithStatus.value = [...roads.value]

  exportRoadStore.fetchRoads(currentPage)
  currentPage = 1
}

function selectedOnly() {
  roadWithStatus.value = [...selectedRoads.value]

  pagination.value = Object.assign(pagination.value, {
    current: 1,
    lastPage: 1
  })
}

async function updateRoads() {
  const withSkdf = selectedRoads.value.filter((el) => el.skdf_number)

  if (withSkdf.length !== selectedRoads.value.length) {
    isOpen.value = true
    withoutSkdf.value = selectedRoads.value.filter((el) => !el.skdf_number)
  } else {
    dataToSkdf()
  }
}

function closeModal() {
  isOpen.value = false
}

function fillSkdf(params: { value: string; id: number }) {
  const road = selectedRoads.value.find((el) => el.id === params.id)

  if (road) road.skdf_number = params.value
}

function removeSelected(id: number) {
  withoutSkdf.value = withoutSkdf.value.filter((el) => el.id !== id)
  selectedRoads.value = selectedRoads.value.filter((el) => el.id !== id)
}

function dataToSkdf() {
  closeModal()

  if (exportRoadStore.skdfToken) {
    loading.value = true

    const token = JSON.parse(exportRoadStore.skdfToken)

    const sendRequest: Promise<IQueueResponse[][]> = Promise.all(selectedRoads.value.flatMap((road) => ([
        exportRoadsApi.sendGeometryToSkdf({
          token: token.access_token,
          roadId: +road.id,
          idSkdf: +road.skdf_number!
        }),
        exportRoadsApi.sendRoadCardToSkdf({
          token: token.access_token,
          roadId: +road.id,
          idSkdf: +road.skdf_number!
        })
    ])))

    sendRequest
      .then((params) => {
        const ids = params.filter((...rest) => rest[1] % 2 !== 0 && rest[1] !== 0).flat()

        if (!ids.length) return

        toastStore.toast.success('Успешно!', 'Данные отправлены в СКДФ', 7000)

        ids.forEach(async (id) => {
          const task = await queueApi.fetchTask(id.qid)
          const road = selectedRoads.value.find((road) => roads.value.find((el) => el.id === road.id && task.task_object_id === el.id)) as IRoadWithStatus

          if (!road) return

          road.task_status = TaskStatus.IN_PROGRESS

          checkExportStatus({
            qid: task.id,
            roads: selectedRoads.value
          })
        })
      })
      .catch(() => {
        toastStore.toast.error('Ошибка!', 'При отправке данных произошла ошибка. Попробуйте заново', 7000)
      })
      .finally(() => {
        loading.value = false
      })
  }
}

function defineStatusName(status: string) {
  return status ? (status === TaskStatus.COMPLETED ? 'Завершено' : status === TaskStatus.FAILED ? 'Ошибка' : 'В процессе') : '-'
}
</script>

<template>
  <div class="export">
    <AppPreloaderInteractive v-if="loading" />
    <h2 class="export__title">Экспорт дорог в СКДФ</h2>
    <section class="export__nav">
      <InputField
        class="export__search"
        type="search"
        :model-value="search"
        placeholder="Найти..."
        @input="searchRoad"
      />
      <div v-if="selectedRoads.length && skdfToken" class="export__selection">
        <Button type="button" class="export__select-only" @click="clear" text="Отменить выделение"></Button>
        <Button type="button" class="export__select-only" @click="selectedOnly" text="Только выделенные"></Button>
        <Button type="button" class="export__select-only" @click="updateRoads" text="Экспортировать данные"></Button>
      </div>
    </section>
    <section class="export__table">
      <div class="export__row export__row--header">
        <div class="export__cell export__cell--select">
          <AppCheckbox name="" :checked="selectAllCb" @toggle-checkbox="selectAll" />
        </div>
        <div class="export__cell export__cell--name">Наименование</div>
        <div class="export__cell">Идентификационный номер</div>
        <div class="export__cell">Учетный номер</div>
        <div class="export__cell">СКДФ номер</div>
        <div class="export__cell export__cell--status">Статус</div>
      </div>
      <div
        v-for="(road, index) in roadWithStatus"
        :key="index + '-export-road'"
        class="export__row"
        @click="(e) => select(e, road)"
      >
        <div class="export__cell export__cell--select">
          <AppCheckbox
            name=""
            :checked="!!selectedRoads.find((selected) => selected.id === road.id)"
          />
        </div>
        <div class="export__cell export__cell--name" :title="road.short_name">
          <RouterLink class="export__link" :to="{ name: 'Road', params: { id: road.id }}" target="_blank">{{ road.name }}</RouterLink>
        </div>
        <div class="export__cell">{{ road.identification_number }}</div>
        <div class="export__cell">{{ road.account_number }}</div>
        <div class="export__cell">{{ road.skdf_number }}</div>
        <div class="export__cell export__cell--status" :title="road.task_status === TaskStatus.FAILED ? road.status_errors : road.task_status ? defineStatusName(road.task_status) : '—'">{{ road.task_status ? defineStatusName(road.task_status) : '—' }}</div>
      </div>
    </section>
    <section class="export__pagination">
      <AppPagination
        :current="pagination.current"
        :max="pagination.lastPage"
        :left="5"
        :navigation="true"
        @change-page="changePage"
      ></AppPagination>
    </section>
  </div>
  <AppPopup :is-open="isOpen" title="Заполните недостающие данные!" @close-modal="closeModal">
    <SkdfPopup
      :roads="withoutSkdf"
      @fill-skdf="fillSkdf"
      @remove-selected="removeSelected"
      @to-skdf="dataToSkdf"
    ></SkdfPopup>
  </AppPopup>
</template>

<style lang="scss" scoped>
.export {
  display: grid;
  align-content: start;
  height: 100%;
  min-height: calc(100vh - 115px - 3.75rem - 75px);
  margin-block-end: 45px;

  &__title {
    color: $c-white;
  }

  &__nav {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    height: 45px;
    margin-block-start: 65px;
  }

  &__table {
    display: grid;
    margin-block-start: 40px;
    color: $c-white;
  }

  &__cell {
    display: grid;
    align-items: center;
    justify-content: center;
    padding-block: 10px;
    padding-inline: 15px;
    border-right: 1px solid $c-gray-20;

    &--select {
      border-left: 1px solid $c-gray-20;
    }

    &--name {
      justify-content: start;
      line-height: 28px;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &--status {
      width: 155px;
    }
  }

  &__selection {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 15px;
    width: max-content;
    margin-block-start: 20px;
  }

  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 50px 1fr 220px 180px 180px;
    height: auto;
    min-height: 60px;
    transition: all 0.1s linear;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid $c-gray-20;
    cursor: pointer;

    &--header {
      height: auto;
      background-color: rgba($c-gray-90, 0.1);
      font-size: 18px;
      font-weight: 800;
      cursor: default;

      .export__cell {
        text-align: center;

        &--name {
          justify-content: center;
          color: $c-white;
        }
      }
    }

    &:hover {
      background-color: rgba($c-gray-90, 0.03);
    }
  }

  &__search {
    display: grid;
    width: 450px;
    height: 100%;
    font-family: $f-family-base;
  }

  &__buttons {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 35px;
  }

  &__button {
    height: 100%;
    color: $c-white;
    font-size: 17px;

    &--clear {
      width: 300px;
    }
  }

  &__clear-select,
  &__select-only {
    display: inline-block;
    height: auto;
    padding: 10px 15px;
    border: 1px solid $c-orange;
    border-width: 2px;
    background-color: transparent;
    color: $c-gray-90;
    font-family: Bender, sans-serif;
    font-size: 1.05rem;
    font-weight: 700;
    line-height: 100%;
    cursor: pointer;
  }

  &__pagination {
    margin-block-start: 45px;
  }
}
</style>
