<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'

import { useExportRoadStore } from '@/stores/export'
import { useToastStore } from '@/stores'

import InputField from '@/components/ui/InputField.vue'
import Button from '@/components/ui/Button.vue'
import AppPreloaderInteractive from '../ui/AppPreloaderInteractive.vue'
import { exportRoadsApi } from '@/api/export'
import Skdf from '@/assets/images/skdf.svg'

const emits = defineEmits(['close-modal'])

const exportRoadStore = useExportRoadStore()
const toastStore = useToastStore()

const loading = ref(false)
const error = ref(false)

const { skdfForm } = storeToRefs(exportRoadStore)

async function login() {
  try {
    loading.value = true

    const loginData = await exportRoadsApi.fetchToken(skdfForm.value) as {
      access_token: string
      expires_in: number
      profile: unknown
      token_type: string
    }

    exportRoadStore.skdfToken = JSON.stringify({
      profile: loginData.profile,
      access_token: loginData.access_token
    })

    error.value = false

    emits('close-modal')
    toastStore.toast.success('Успех!', 'Авторизация завершена', 3000)
  } catch {
    error.value = true
  } finally {
    loading.value = false
  }
}

function changeSkdfLogin(type: string, e: InputEvent) {
  const t = e.target as HTMLInputElement

  skdfForm.value[type] = t.value
}
</script>

<template>
  <form class="form export__modal">
    <AppPreloaderInteractive v-if="loading"/>
    <div>
      <Skdf class="export__icon" :class="{'with-error': error}"/>
    </div>
    <div class="export__wrapper">
      <div class="export__form">
        <div class="export__item">
          <p class="export__name">Логин</p>
          <InputField
            class="export__input"
            :model-value="skdfForm.username"
            type="text"
            placeholder="Логин"
            @input="(e: InputEvent) => changeSkdfLogin('username', e)"
          ></InputField>
        </div>
        <div class="export__item">
          <p class="export__name">Пароль</p>
          <InputField
            :model-value="skdfForm.password"
            class="export__input"
            type="password"
            placeholder="Пароль"
            @input="(e: InputEvent) => changeSkdfLogin('password', e)"
          ></InputField>
        </div>
      </div>
      <p v-if="error" class="error">
        Ошибка! Пожалуйста, проверьте правильность введенных<br>данных или попробуйте заново.
      </p>

      <div class="export__login" :class="{ error }">
        <Button
          class="export__btn export__btn--cancel"
          type="button"
          text="Отмена"
          @click="emits('close-modal')"
        ></Button>
        <Button text="Логин" @click="login" :disabled="loading" type="button" class="export__btn"></Button>
      </div>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.error {
  color: #ca3433;
  font-weight: 600;
  line-height: 120%;
}

.export {
  &__wrapper {
    display: grid;
    position: relative;
    z-index: 2;
    grid-gap: 30px 0;
  }

  &__icon {
    position: absolute;
    top: 45%;
    right: 27%;
    transform: scale(12);

    &.with-error {
      top: 45%;
      right: 27%;
      transform: scale(15);
    }
  }

  &__modal {
    position: relative;
    padding: 35px 45px;
    overflow: hidden;
    background-color: rgba(#333, 0.3);
    color: $c-white;
  }

  &__form {
    display: grid;
    grid-gap: 25px 0;
  }

  &__item {
    display: grid;
    grid-gap: 12px 0;
  }

  &__input {
    width: 350px;
    height: 45px;
  }

  &__login {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 20px;
    justify-content: start;
    margin-block-start: 10px;

    &.error {
      margin-block-start: 0;
    }
  }
}

button.export__btn {
  width: max-content;
  min-width: auto;
  height: 100%;
  padding: 12px 56px;
  transition: all 0.1s ease-in;
  color: #fff;
  font-family: Bender, sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.02rem;
  line-height: 100%;

  &:hover:not(:disabled) {
    background-color: #bd4f2b;
    transition: all 0.1s ease-in;
  }

  &--cancel {
    border: 2px solid $c-orange;
    background-color: transparent;

    &:hover:not(:disabled) {
      background-color: #666;
      border-color: #666;
    }
  }
}
</style>
