import { ITask, ITaskResponse } from "@/types/Queue"
import { fetchWrapper } from "./fetchApi"

export const queueApi = {
  async fetchTasks() {
    try {
      const response = await fetchWrapper.get('/queue/tasks/?page_number=1&elements_on_page=1000') as ITaskResponse

      return response.data
    } catch {
      throw new Error('Failed to fetch tasks')
    }
  },
  async fetchTask(id: number) {
    try {
      const response = await fetchWrapper.get(`/queue/${id}`) as ITask

      return response
    } catch {
      throw new Error('Failed to fetch task ' + id)
    }
  }
}